import React from 'react'
import { graphql, StaticQuery } from 'gatsby'
import styled from 'react-emotion'

import ProductListingHeader from './ProductListingHeader';
// import ProductListingItem from './ProductListingItem';
import ProductForm from '../ProductPage/ProductForm'

import { Submit } from '../shared/FormElements'
import { Button } from '../shared/Buttons'

import { breakpoints, spacing, colors } from '../../utils/styles';

const ProductListingContainer = styled(`div`)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: ${spacing.lg}px;

  @media (min-width: ${breakpoints.desktop}px) {
    flex-direction: row;
    flex-wrap: wrap;
    padding: ${spacing['2xl']}px;
  }
`;

const ButtonStyles = styled('div')`
  display: flex;
  align-items: flex-end;

  a {
    font-size: 16px;
    letter-spacing: 0.5px;
    display: inline-block;
    border: none;
    background: transparent;

    &:hover {
      text-decoration: underline;
      outline: none;
      border: none;
      box-shadow: none;
    }
  }
`

const Container = styled.div`
  max-width: 1680px;
  margin: auto;
`

const ButtonGroup = styled('div')`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  @media (max-width: ${breakpoints.mobile}px) {
    flex-wrap: wrap;
  }

  button,
  a {
    font-size: 16px;
    letter-spacing: 0.5px;
  }

  form {
    padding: 0;

    &:not(:last-of-type) {
      margin-right: 1rem;
    }
  }

`

const SideBySide = styled('section')`
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  flex-wrap: wrap;

  @media (min-width: ${breakpoints.desktop}px) {
    flex-direction: ${props => props.reversed ? 'row-reverse' : 'row'};
  }
`

const Content = styled('div')`
  padding: ${spacing['3xl']}px ${spacing.xl}px;
  margin: ${spacing['3xl']}px 0;

  @media (min-width: ${breakpoints.desktop}px) {
    width: ${props => props.desktop ? props.desktop * 100 : '50'}%;
    max-width: ${props => props.desktop ? props.desktop * 100 : '50'}%;
  }
`

const Image = styled('div')`

  img {
    max-width: 100%;
    height: auto;
  }

  @media (min-width: ${breakpoints.desktop}px) {
    width: ${props => props.desktop ? props.desktop * 100 : '50'}%;
    max-width: ${props => props.desktop ? props.desktop * 100 : '50'}%;
  }
`

const ProductListing = () => (
  <StaticQuery
    query={graphql`
      query ProductListingQuery {
        collection: shopifyCollection(handle: { eq: "overcoming-anxiety"}) {
          products {
            id
            handle
            title
            description
            productType
            availableForSale
            variants {
              shopifyId
              title
              price
              availableForSale
            }
            images {
              id
              localFile {
                childImageSharp {
                  fluid(maxWidth: 910, maxHeight: 910) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={({ collection }) => (
      <>
        <ProductListingHeader>
          <ButtonGroup>
            {collection.products.map((product, i) => {
              if (product.handle === "overcoming-anxiety-ebook" && product.availableForSale) {
                return (
                  <ProductForm
                    key={i}
                    id={product.id}
                    variants={product.variants}
                    alternateText={"Order ebook"}
                    buttonOnly
                    showPrice
                    textOnly
                  />
                )
              }
              else if (product.handle === "overcoming-anxiety-audiobook" && product.availableForSale) {
                return (
                  <ProductForm
                    key={i}
                    id={product.id}
                    variants={product.variants}
                    alternateText={"Order Audiobook"}
                    buttonOnly
                    showPrice
                    textOnly
                  />
                )
              }
              else if (product.availableForSale) {
                return <ProductForm key={i} id={product.id} variants={product.variants} alternateText={"Order Paperback"} buttonOnly />
              }
            })}
            <ButtonStyles>
              <Button
                  href="https://perrynoble-downloads.s3.amazonaws.com/theGrowthCo/books/Devo_For_The_Devo.pdf"
                  target="_blank"
                  textOnly
                >
                  Download Free Devotional
                </Button>
            </ButtonStyles>
          </ButtonGroup>
        </ProductListingHeader>
        <SideBySide style={{ backgroundColor: '#f5f5f5' }}>
          <Content desktop={4/5}>
            <h2>About the Book</h2>
            <p>Have You Ever Wrestled With Anxiety?</p>
            <p>Anxiety levels are at an all time high in the world--and are increasing more and more each and every day.</p>
            <p>Too many of us are dominated by worry, restlessness, trouble with sleep and even panic attacks.</p>
            <p>And while I do not believe there is a magic prayer we can pray to make the anxiety go away - I do believe there is a way we can begin our journey through the process.</p>
            <p>Which is why I've written, "Overcoming Anxiety" - a 30 day guide to start winning the war with anxiety.</p>
            <p>Warning:  This book is raw, some may even find a few words offensive; however, I felt it was time to really deal with the issues instead of dancing around the things that need to really be said.</p>
            <p>I know what's its like to be paralyzed by the worry, fear and doubt that anxiety brings into our lives; however, I've learned how to walk through it without it absolutely crushing my soul - and I share a bit of my journey in this book because--if I can do it, you can too!!</p>
            <p>Yes, you CAN win the war against worry - and this book will help you start that process.</p>
{/*
            <h4>The first printing has sold out, pre-order your paperback now for our second printing, ebook is delivered immediately.</h4>
            */}
            <ButtonGroup>
              {collection.products.map((product, i) => {
                if (product.handle === "overcoming-anxiety-ebook" && product.availableForSale) {
                  return (
                    <ProductForm
                      key={i}
                      id={product.id}
                      variants={product.variants}
                      alternateText={"Order ebook"}
                      buttonOnly
                      showPrice
                      textOnly
                    />
                  )
                }
                else if (product.handle === "overcoming-anxiety-audiobook" && product.availableForSale) {
                  return (
                    <ProductForm
                      key={i}
                      id={product.id}
                      variants={product.variants}
                      alternateText={"Order Audiobook"}
                      buttonOnly
                      showPrice
                      textOnly
                    />
                  )
                }
                else if (product.availableForSale) {
                  return <ProductForm key={i} id={product.id} variants={product.variants} alternateText={"Order Paperback"} buttonOnly />
                }
              })}
              <ButtonStyles>
                <Button
                    href="https://perrynoble-downloads.s3.amazonaws.com/theGrowthCo/books/Devo_For_The_Devo.pdf"
                    target="_blank"
                    textOnly
                  >
                    Download Free Devotional
                  </Button>
              </ButtonStyles>
            </ButtonGroup>

          </Content>
        </SideBySide>
        <SideBySide reversed={true}>
            <Image desktop={2/5} >
              <img
                src="https://d120pbh18rvtk.cloudfront.net/images/cached/images/remote/https_s3.amazonaws.com/perrynoble-images/perrynoble/pn_hero_2000_890_90.jpg"
                alt=""
              />
            </Image>
            <Content desktop={3/5}>
                <h2>About the Author</h2>
                <p>Perry is the Founding and Senior Pastor of Second Chance Church, and President/CEO of the Growth Company. He is a gifted communicator and teacher, convicted about speaking the truth as plainly as possible. God has given him a vision and a passion to reach people far from God and teach them how to follow Jesus step by step.</p>
                <p>Perry’s heart for the big C Church drives him to serve pastors and church leaders around the globe.</p>
                <p>His enthusiasm for learning and leadership led him to become the recipient of the 2015 John C. Maxwell Leadership Award.
                </p>
                <p>He is the author of several books including Unleash!, Overwhelmed, The Most Excellent Way To Lead and Overcoming Anxiety.</p>
              <ButtonGroup>
                {collection.products.map((product, i) => {
                  if (product.handle === "overcoming-anxiety-ebook" && product.availableForSale) {
                    return (
                      <ProductForm
                        key={i}
                        id={product.id}
                        variants={product.variants}
                        alternateText={"Order ebook"}
                        buttonOnly
                        showPrice
                        textOnly
                      />
                    )
                  }
                  else if (product.handle === "overcoming-anxiety-audiobook" && product.availableForSale) {
                    return (
                      <ProductForm
                        key={i}
                        id={product.id}
                        variants={product.variants}
                        alternateText={"Order Audiobook"}
                        buttonOnly
                        showPrice
                        textOnly
                      />
                    )
                  }
                  else if (product.availableForSale) {
                    return <ProductForm key={i} id={product.id} variants={product.variants} alternateText={"Order Paperback"} buttonOnly />
                  }
                })}
                <ButtonStyles>
                  <Button
                      href="https://perrynoble-downloads.s3.amazonaws.com/theGrowthCo/books/Devo_For_The_Devo.pdf"
                      target="_blank"
                      textOnly
                    >
                      Download Free Devotional
                    </Button>
                </ButtonStyles>
              </ButtonGroup>
            </Content>
        </SideBySide>
      </>
    )}
  />
);

export default ProductListing;
