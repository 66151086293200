import React from 'react';
import styled from 'react-emotion';

import { breakpoints, colors, fonts, spacing } from '../../utils/styles';

const ProductListingHeaderRoot = styled(`header`)`
  display: flex;
  flex-direction: row;
  margin: 0 auto;
  //max-width: 40em;
  // padding: ${spacing.lg}px;
  //text-align: center;
  align-items: center;

  @media (max-width: ${breakpoints.desktop}px) {
    flex-wrap: wrap;
  }
`;

const Title = styled(`h1`)`
  font-family: ${fonts.heading};
  font-size: 2.4rem;
  font-weight: 600;
  letter-spacing: -0.02em;
  line-height: 1;
  margin: 0;
  margin-bottom: ${spacing.lg}px;

  @media (min-width: ${breakpoints.desktop}px) {
    font-size: 3rem;
  }
`;

const Subheading = styled(`h3`)`
  // color: ${colors.brand};
  font-family: ${fonts.heading};
  font-size: 1.5rem;
  line-height: 1;
  margin: 0;
  margin-top: ${spacing.sm}px;

  // @media (min-width: ${breakpoints.desktop}px) {
  //   font-size: 1.5rem;
  // }
`;

const CallOut = styled(`h5`)`
  font-family: ${fonts.heading};
  font-size: 1.25rem;
  line-height: 1;
  margin: 0;
  //opacity: 0.9;
  margin-top: ${spacing['2xl']}px;

  @media (min-width: ${breakpoints.desktop}px) {
    font-size: 1.5rem;
  }
`;

const Content = styled('div')`
  padding: ${spacing['3xl']}px ${spacing.lg}px;
  margin: ${spacing['3xl']}px 0;

  @media (min-width: ${breakpoints.desktop}px) {
    width: 50%;
    max-width: 50%;
  }
`

const Image = styled('div')`

  img {
    max-width: 100%;
    height: auto;
  }

  @media (min-width: ${breakpoints.desktop}px) {
    width: 50%;
    max-width: 50%;
  }
`

const Intro = styled(`p`)`
  color: ${colors.text};
  font-size: 1rem;
  line-height: 1.4;
  margin: 0;
  margin-top: ${spacing.xl}px;

  @media (min-width: ${breakpoints.desktop}px) {
    font-size: 1.1rem;
    line-height: 1.6;
  }
`;

const ProductListingHeader = ({ children }) => (
  <ProductListingHeaderRoot>
    <Content>
      <Title>Overcoming Anxiety</Title>
      <Subheading>A 30 Day Guide to Start Winning The War with Anxiety</Subheading>
      <CallOut>Buy • Read It Daily • Experience Hope</CallOut>
      <Intro>
        Warning:  This book is raw, some may even find a few words offensive; however, I felt it was time to really deal with the issues instead of dancing around the things that need to really be said.
      </Intro>
      {/*
        <h4>The first printing has sold out, pre-order your paperback now for our second printing, ebook is delivered immediately.</h4>
      */}
      {children}
    </Content>
    <Image>
      <img src="https://perrynoble-images.s3.amazonaws.com/perrynoble/books/book_one.jpg" alt="Overcoming Anxiety Book"/>
    </Image>
  </ProductListingHeaderRoot>
);

export default ProductListingHeader;
